<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">문항별 통계</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="form-inp sm">
          <v-select
            :items="$store.getters['userStore/GE_USER_COMPANY']"
            item-text="CD_NM"
            item-value="CD"
            outlined
            hide-details
            label="회사구분"
            placeholder="선택하세요"
            v-model="grid01Search.ASP_NEWCUST_KEY"
            @change="selectRtnLm"
            :readonly="RDOnly"
          >
          <template v-slot:label>
              회사구분
            <v-icon color="#EF9191">mdi-circle-small</v-icon>
          </template>
          </v-select>
        </div>
        <div class="form-inp sm ml-2">
          <v-select
            :items="mixin_common_code_get(allCodeList,'PLT018','전체')"
            item-text="text"
            item-value="value"
            outlined
            hide-details
            label="유형타입"
            placeholder="선택하세요"
            v-model="grid01Search.LM_TY"
          ></v-select>
        </div>
        <div class="sm ml-2">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar"
                append-icon="svg-calendar"
                label="전체평가기간"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                  v-model="grid01Search.START_DATE"
                  no-title
                  dark
                  color="#F7AFAE"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                ></v-date-picker>
              <v-date-picker
                  v-model="grid01Search.END_DATE"
                  dark
                  color="#F7AFAE"
                  no-title
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  :min="grid01Search.START_DATE"
                ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                outlined
                class="btn-default"
                @click="menu1 = false"
              >확인</v-btn>
            </div>
          </v-menu>
        </div>

        <div class="ml-2">
          <v-text-field
            class="form-inp lg"
            name="name"
            label="시험명"
            hide-details
            outlined hint=""
            v-model="grid01Search.LM_ID_NM"
            @keyup.enter="selectRtnLm"
            persistent-hint>
          </v-text-field>
        </div>

        <div class="ml-auto align-self-center ">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" @click="selectRtnLm" @keyup.enter="selectRtnLm" >조회</v-btn>
        </div>
      </div>
    </div>

    <!-- 상담품질평가 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">상담품질평가 목록</h2>
      <div class="box-ct">
        <v-data-table
          dense
          :headers="gridDataHeaders"
          :items="gridDataText"
          item-key="LM_ID"
          hide-default-footer
          class="grid-default case04"
          single-select
          calculate-widths
          fixed-header
          height="317px"
          :item-class="isActiveRow"
          @click:row="setGrid01Select"
          no-data-text="검색 결과가 없습니다."
        ></v-data-table>
      </div>
    </div>

    <!-- 상세 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">상세</h2>
      <div class="table-form">
        <table>
          <caption class="hide">
            사용자정보 항목
          </caption>
          <colgroup>
            <col width="127px" />
            <col width="450px" />
            <col width="127px" />
            <col width="450px" />
            <col width="127px" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                시험명
              </th>
              <td>
                {{detail.LM_ID_NM}}
              </td>
              <th scope="row">
                시험일자
              </th>
              <td>
                {{detail.LM_ST_DTTM}} ~ {{detail.LM_EN_DTTM}}
              </td>
              <th scope="row">
                총응시자수
              </th>
              <td>
                {{detail.LM_DONE_CNT}}명
              </td>
            </tr>
            <tr>
              <th scope="row">
                평균
              </th>
              <td>
                {{detail.AVG_RST_SUM}}점
              </td>
              <th scope="row">
                표준편차
              </th>
              <td>
                {{detail.STD_RST_SUM}}
              </td>
              <th scope="row">
                분산
              </th>
              <td>
                {{detail.VARI_RST_SUM}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="list">
        <h3>참고</h3>
        <ul>
          <li>단답형,주관식 문제의 부분 점수득점자도 정답자로 인정한 결과입니다.</li>
          <li>표준편차는 특성값에 관한 손포도를 나타내는 도수특성값으로 표준편차가 0일 때는 관측값의 모두가 동일한 크기이고, 표준편차가 클수록 관측값 중에는 평균에서 떨어진 값이 많이 존재한다는 의미입니다.</li>
          <li>분산은 평균 기대값으로부터 얼마나 떨어진 곳에 분포하시는지를 가늠하는 값이다.</li>
        </ul>
      </div>

      <template v-if="qsList.length > 0">
        <div class="detail_wrap">
          <template v-for="(item, index) in qsList">
            <div :key="item.LM_QS_ID">
              <div class="table-form">
                <table>
                  <caption class="hide">
                    상세 표에 대한 정보가 기재된표
                  </caption>
                  <colgroup>
                    <col width="127px" />
                    <col width="300px" />
                    <col width="127px" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">
                        {{index + 1}}번 문제
                      </th>
                      <td colspan="3">
                        {{item.LM_QS_NM}}
                      </td>
                    </tr>
                    <template v-for="ve in veList">
                      <template v-if="item.LM_QS_ID == ve.LM_QS_ID">
                        <tr :key="ve.LM_QS_VE_ID">
                          <th scope="row">
                            <template v-if="item.LM_QS_TY == 20">
                              응답
                            </template>
                            <template v-else>
                              {{ve.VE_NUMBER}}번
                            </template>
                          </th>
                          <td>
                            {{ve.ANS_CNT}}명 ({{Number(ve.ANS_CNT / item.QS_ANS_COUNT * 100).toFixed(1)}}%)
                          </td>
                          <td colspan="2">
                            <div class="graph_bar">
                              <span :style="{width : Number(ve.ANS_CNT / item.QS_ANS_COUNT * 100).toFixed(1) + '%'}">{{Number(ve.ANS_CNT / item.QS_ANS_COUNT * 100).toFixed(1)}}</span>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </template>
                    <tr>
                      <th scope="row">
                        무응답
                      </th>
                      <td>
                        {{item.LM_ANS_COUNT - item.QS_ANS_COUNT}}명 ({{((Number(item.LM_ANS_COUNT) - Number(item.QS_ANS_COUNT)) / Number(item.LM_ANS_COUNT) * 100).toFixed(1)}}%)
                      </td>
                      <td colspan="2">
                        <div class="graph_bar">
                          <span :style="{width : ((Number(item.LM_ANS_COUNT) - Number(item.QS_ANS_COUNT)) / Number(item.LM_ANS_COUNT) * 100).toFixed(1) + '%' }">{{((Number(item.LM_ANS_COUNT) - Number(item.QS_ANS_COUNT)) / Number(item.LM_ANS_COUNT) * 100).toFixed(1)}}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        정답
                      </th>
                      <td>
                        <template v-if="item.LM_QS_TY == 20">
                         {{item.QS_CORRECT_COUNT}}명 ({{(Number(item.QS_CORRECT_COUNT) / Number(item.LM_ANS_COUNT) * 100).toFixed(1)}})%
                        </template>
                        <template v-else>
                          [{{getCorrectNum(item.LM_QS_ID)}}] {{item.QS_CORRECT_COUNT}}명 ({{(Number(item.QS_CORRECT_COUNT) / Number(item.LM_ANS_COUNT) * 100).toFixed(1)}}%)
                        </template>
                      </td>
                      <td colspan="2">
                        <div class="graph_bar">
                          <span :style="{width : (Number(item.QS_CORRECT_COUNT) / Number(item.LM_ANS_COUNT) * 100).toFixed(1) + '%'}">{{(Number(item.QS_CORRECT_COUNT) / Number(item.LM_ANS_COUNT) * 100).toFixed(1)}}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        난이도
                      </th>
                      <td colspan="1">
                        {{item.LM_QS_DIC_NM}}
                      </td>
                      <th scope="row">
                        실제난이도
                      </th>
                      <td colspan="1">
                        {{getDic(item)}}
                      </td>
                      <!-- <th scope="row">
                        분류
                      </th>
                      <td>
                        자격
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>

        </div>

      </template>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
export default {
  name: "MENU_M710401", //name은 'MENU_' + 파일명 조합
  components: {},
  mixins: [mixin],
  data() {
    return {
      grid01Search : {
        LM_TY : "",
        ASP_NEWCUST_KEY : "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        START_DATE : '',
        END_DATE : '',
        LM_ID_NM : "",
      },
      menu1: false,
      gridDataHeaders: [
        {
          text: 'NO',
          sortable: true,
          value: 'ROW_NUMBER',
          align: "center",
        },
        { text: "회사구분", value: "ASP_CUST_NM", align: "center", sortable: true },
        { text: "유형타입", value: "LM_TY_NM", align: "left", sortable: true },
        { text: "시험명", value: "LM_ID_NM", align: "left", sortable: true },
        { text: "진행상태", value: "LM_PG_ST_NM", align: "center", sortable: true },
        { text: "시험시작일", value: "LM_ST_DTTM", align: "center", sortable: true },
        { text: "시험종료일", value: "LM_EN_DTTM", align: "center", sortable: true },
        { text: "제한시간", value: "LM_LIM_TIME", align: "center", sortable: true },
        { text: "문제수", value: "LM_EVA_RST_COUNT", align: "center", sortable: true },
        { text: "인원", value: "LM_DATA_COUNT", align: "center", sortable: true },
        { text: "제출자", value: "LM_DONE_CNT", align: "center", sortable: true },
        { text: "미제출자", value: "LM_NON_DONE_CNT", align: "center", sortable: true },
        { text: "총점", value: "LM_EVA_SUM", align: "center", sortable: true },
      ],
      gridDataText: [],
      grid01Selected : {},
      detail : {},
      qsList : [],
      veList : [],
      allCodeList : [],
      groupCdList : ['PLT018'],
      
      RDOnly:false,
    };
  },
  async mounted(){
    this.allCodeList = await this.mixin_common_code_get_all(this.groupCdList);
  
    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.grid01Search.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
  methods: {
    async selectRtnLm(){
      if(this.grid01Search.ASP_NEWCUST_KEY == undefined || this.grid01Search.ASP_NEWCUST_KEY == ''){
        this.common_alert("회사구분을 선택해주세요.","error");
      }else{
        this.grid01Selected = {};
        this.detail = {};
        this.qsList = [];
        this.veList = [];
        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/statistics/phone/lm/list";
        requestData.headers["SERVICE"] = "statistics.phone.lm";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData = this.grid01Search;

        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.gridDataText = response.DATA;
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }

      }

    },
    isActiveRow(item){
      return item.LM_ID == this.grid01Selected.LM_ID? 'active':'';
    },
    setGrid01Select(item){
      this.grid01Selected = item;
      this.selectRtnLmDetail();
    },
    getCorrectNum(LM_QS_ID){
      let rtn = "";
      this.veList.map(e => {
        if(e.LM_QS_ID == LM_QS_ID && e.ANS_YN == 'Y'){
          if(rtn != ""){
            rtn += ", "
          }
          rtn += e.VE_NUMBER
        }
      });

      return rtn;
    },
    getDic(item){
      let rtn = "";

      let persent = 0;
      persent = (Number(item.QS_CORRECT_COUNT) / Number(item.LM_ANS_COUNT) * 100).toFixed(1);

      if(persent >= 70){
        rtn = "하"
      }else if(persent >= 50){
        rtn = "중"
      }else{
        rtn = "상"
      }

      return rtn;
    },
    async selectRtnLmDetail(){
      if(this.grid01Selected.LM_ID != undefined && this.grid01Selected.LM_ID != null && this.grid01Selected.LM_ID != ""){
        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/statistics/phone/lm/detail/qs/inqire";
        requestData.headers["SERVICE"] = "statistics.phone.lm.detail.qs";
        requestData.headers["METHOD"] = "inqire";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData['LM_ID'] = this.grid01Selected.LM_ID;
        requestData.sendData['ASP_NEWCUST_KEY'] = this.grid01Selected.ASP_NEWCUST_KEY;


        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.detail = response.DETAIL[0];
          this.qsList = response.qsList;
          this.veList = response.veList;

          console.log("detail : " + JSON.stringify(this.detail))
          console.log("qsList : " + JSON.stringify(this.qsList))
          console.log("veList : " + JSON.stringify(this.veList))
        }
      }
    },
  },

  computed: {
    dateRangeText () {
      var newStartDate = this.grid01Search.START_DATE;
      var newEndDate = this.grid01Search.END_DATE;
      let returnVal = "";
      if(newStartDate !== '' && newEndDate !== ''){
        returnVal = newStartDate + " ~ " + newEndDate
      }
      return returnVal;
      // return this.dates.join(' ~ ')
    },

  },

};
</script>

<style>
.list h3{padding:30px 0 10px; font-size:14px; }
.list ul{padding:0; margin-bottom:20px; }
.list li{position:relative; margin:0 0 2px; padding:0 0 0 10px; list-style: none; font-size:13px; line-height:1.5; }
.list li:before{position:absolute; left:0; top:10px; width:5px; height:1px; background-color:#333; display:block; content:""; }
.detail_wrap{display:flex; flex-wrap:wrap; }
.detail_wrap>div{margin:0 auto 5px 0; width: 49%;}
.graph_bar{position:relative; width:100%; height:10px; }
.graph_bar span{position:absolute; left:0; top:0; height:10px; background-color:#8b98dd; display:block; text-indent:-9999px;}

</style>
